import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}}):_c(VTreeview,{attrs:{"items":_vm.treeData},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" "),_c(VBtn,{staticClass:"text-capitalize ml-2",attrs:{"small":"","text":!hover,"color":"primary"},on:{"click":function($event){return _vm.move(item)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-folder-arrow-right-outline")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" Move here ")]):_vm._e()],1)],1)]}}],null,true)})],1)]}},{key:"prepend",fn:function(ref){return [_c(VIcon,[_vm._v(" mdi-folder ")])]}}])}),_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VBtn,{staticClass:"text-capitalize ml-2",attrs:{"small":"","text":!hover,"color":"primary"},on:{"click":function($event){return _vm.move({ id: _vm.folderId })}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-folder-arrow-right-outline")]),_c('span',[_vm._v(" Move here ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
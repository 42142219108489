<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <v-treeview v-else :items="treeData">
      <template #label="{ item }">
        <div>
          <v-hover v-slot="{ hover }">
            <div>
              {{ item.name }}
              <v-btn
                small
                :text="!hover"
                color="primary"
                class="text-capitalize ml-2"
                @click="move(item)"
              >
                <v-icon class="mr-2">mdi-folder-arrow-right-outline</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp"> Move here </span>
              </v-btn>
            </div>
          </v-hover>
        </div>
      </template>
      <template #prepend="{}">
        <v-icon> mdi-folder </v-icon>
      </template>
    </v-treeview>
    <v-hover v-slot="{ hover }">
      <v-btn
        small
        :text="!hover"
        color="primary"
        class="text-capitalize ml-2"
        @click="move({ id: folderId })"
      >
        <v-icon class="mr-2">mdi-folder-arrow-right-outline</v-icon>
        <span> Move here </span>
      </v-btn>
    </v-hover>
  </div>
</template>

<script>
import Drive from './drive';
const drive = new Drive();
export default {
  props: {
    folderId: {
      type: String,
      required: true
    },
    fileId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      treeData: [],
      detail: null,
      loading: true
    };
  },
  mounted() {
    this.reloadTree();
  },
  methods: {
    move({ id }) {
      drive
        .moveTo(this.fileId, id)
        .then(() => {
          this.$emit('success');
        })
        .catch(err => {
          this.error = err;
        });
    },
    reloadTree() {
      this.treeData = [];
      this.getChildrens(this.folderId);
    },

    insertData(tree, id, data) {
      if (!id || id === this.folderId) {
        tree.push(...data);
      } else {
        tree.forEach(node => {
          if (node.type === 'folder') {
            if (node.id === id) {
              node.children.push(...data);
            } else {
              this.insertData(node.children, id, data);
            }
          }
        });
      }
    },

    async getChildrens(id) {
      await this.getFolders(id || null);
      this.loading = false;
    },

    getFolders(id) {
      return new Promise((resolve, reject) => {
        drive
          .listFolders({ user: this.user, parentId: id })
          .then(q => {
            const data = [];
            q.forEach(item => {
              data.push({
                ...item.data(),
                id: item.id,
                children: [],
                type: 'folder'
              });
              this.getChildrens(item.id);
            });
            this.insertData(this.treeData, id, data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
</script>

<style>
</style>
